@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
:root {
    --colHintergrund: #EFFBFB;
    --colSchriftAccordion: #FFFFFF;
    --colSchriftAccordionContent: #000000;
    --colSchriftAccordionContentHintergrund: #EFFBFB;
    --colHintergundSelected: #A9F5F2;
    --colHintergrundAccordion: #424242;
    --colHintergrundAccordionAktiv: #727272;
    --colSchriftHeadlineMain: #424242;
    --colSchriftHeadlineScond: #727272;
    --colSchriftNavAktiv: #424242;
    --colSchriftNavInaktiv: #424242;
    --colSchriftNavHover: #088A85;
    --colTabObenAktiv: #A9F5F2;
    --colTabObenInaktiv: #D8D8D8;
    --colTabMitteAktiv: #CEF6F5;
    --colTabMitteInaktiv: #E6E6E6;
    --colTabUntenAktiv: #E0F8F7;
    --colTabUntenInaktiv: #EDEDED;
    --colTabGanzUntenAktiv: #E4FBFA;
    --colTabGanzUntenInaktiv: #F2F2F2;
    --colTabellenHintergrund: #FFFFFF;
    --colTabActiveBolder: #828282;
    --colButtonBackground: #088A85;
    --colButtonBackgroundHover: #077571;
    --colButtonBackgroundDisabled: #098A85;
}

/* Provide sufficient contrast against white background */


code {
    color: #E01A7;
}

.btn-secondary {
    color: #fff;
    background-color: #088A85;
    background-color: var(--colButtonBackground);
    border-color: #077571;
}

    .btn-secondary:hover {
        color: #fff;
        background-color: #077571;
        background-color: var(--colButtonBackgroundHover);
    }

    .btn-secondary:disabled {
        color: #fff;
        background-color: #098A85;
        background-color: var(--colButtonBackgroundDisabled);
        border-color: #077571;
    }

    .btn-secondary:focus {
        color: #fff;
        background-color: #088A85;
        background-color: var(--colButtonBackground);
        border-color: #077571 !important;
    }

    .btn-secondary:active {
        color: #fff;
        background-color: #088A85 !important;
        background-color: var(--colButtonBackground) !important;
        border-color: #077571 !important;
    }

.btn-primary {
    color: #fff;
    background-color: #088A85;
    background-color: var(--colButtonBackground);
    border-color: #077571;
}

    .btn-primary:hover {
        color: #fff;
        background-color: #077571;
        background-color: var(--colButtonBackgroundHover);
    }

    .btn-primary:disabled {
        color: #fff;
        background-color: #098A85;
        background-color: var(--colButtonBackgroundDisabled);
        border-color: #077571;
    }

    .btn-primary:focus {
        color: #fff;
        background-color: #088A85 !important;
        background-color: var(--colButtonBackground) !important;
        border-color: #077571 !important;
    }

    .btn-primary:active {
        color: #fff;
        background-color: #088A85 !important;
        background-color: var(--colButtonBackground) !important;
        border-color: #077571;
    }

* {
    box-sizing: border-box;
}

body {
    background: #EFFBFB !important;
    background: var(--colHintergrund) !important;
    min-height: 100vh;
    min-width: 1500px;
    display: flex;
    font-weight: 400;
    font-family: 'Arial', Arial;
    font-size: 12px;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}

h1, h2, h3, h4, h5, h6, label, span {
    font-weight: 500;
    font-family: 'Arial', Arial;
}

body, html, .App, #root, .auth-wrapper .kslplus-layout {
    width: 100%;
    height: 100%;
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
}

.navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -225px;
    margin-top: -225px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
}

.auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
}

.auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
}

#ksl-plus-forgotpw {
    color: #167bff;
}

    #ksl-plus-forgotpw:hover {
        text-decoration: underline;
    }

.custom-control-label {
    font-weight: 400;
}

.forgot-password,
.forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
}

    .forgot-password a {
        color: #167bff;
    }

/*
.container {
    min-width: -webkit-fill-available;
    max-width: -webkit-fill-available;
}
*/

.kslplus-app-container {
    min-width: 100%;
    min-height: 2000px; /* eingef�gt f�r Safarie auf iPad oder iPhone*/
}

.kslplus-sidebare-container {
    height: 1440px;
    min-width: -webkit-fill-available;
    max-width: -webkit-fill-available;
}


.pro-sidebar {
    display: inline-block;
    vertical-align: top;
    height: 96%;
    width: 30%;
    overflow: auto;
    color: #adadad;
    height: 100%;
    margin: 0;
    min-width: 270px;
    text-align: left;
    transition: width,left,right,.3s;
    position: relative;
    z-index: 1009;
}

.kslplus-link {
    color: white;
}

    .kslplus-link.disabled {
        color: darkgray;
    }

.kslplus-layout {
    height: 100%;
    width: 100%;
}

.kslplus-content {
    height: 100%;
    width: 100%;
}

    .kslplus-content .react-bootstrap-table {
        background-color: #FFFFFF;
        background-color: var(--colTabellenHintergrund);
    }

        .kslplus-content .react-bootstrap-table .table {
        }

.kslplus-vbhaushalts-wrapper .react-bootstrap-table {
    background-color: #FFFFFF;
    background-color: var(--colTabellenHintergrund);
}

.kslplus-haushaltstab-wrapper-top .react-bootstrap-table {
    background-color: #FFFFFF;
    background-color: var(--colTabellenHintergrund);
}

.kslplus-haushaltstab-wrapper-invest {
    background-color: #FFFFFF;
    background-color: var(--colTabellenHintergrund);
    min-width: 1470px;
    overflow: auto;
}

.kslplus-sidebar {
    height: 96% !important;
    width: 10%;
    float: left;
}

.kslplus-header {
    height: 4%;
    width: 100%;
}

.kslplus-content-rightside {
    height: 2000px;
    overflow: scroll;
    padding: 10px;
    background-color: #ffffff;
}

.nav-item {
    list-style-type: none;
}

.nav-link {
    color: aliceblue;
}

.kslplus-row-even {
    background-color: #f4f4f4;
}

.kslplus-row-odd {
    background-color: white;
}

.kslplus-row-sum-0 {
    
}

.kslplus-row-sum-1 {
    
}

.kslplus-row-sum-2 {
    background-color: #f4f4f4;
}

.kslplus-row-sum {
    font-weight: bold;
}

.ksl-modal-drilldown {
    //height: 1200px;
    
    
}


.ksl-modal-fullscreen {
    height: 1200px;
}

.ksl-spinner-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

:where(#ksl-modal-hhDaten) .nav-link {
    color: lightgray;
}

.kslplus-reiter-text-nebenzeile {
    text-align: center;
    font-size: 0.8rem;
}

.kslplus-reiter-text-hauptzeile {
    text-align: center;   
}

.kslplus-haushaltstab-wrapper .row {
    overflow: auto;
}

.kslplus-haushaltstab-wrapper-top .row {
    overflow: auto;
}

.kslplus-haushaltstab-wrapper .react-bootstrap-table {
    background-color: #FFFFFF;
    background-color: var(--colTabellenHintergrund);
}

.kslplus-haushaltstab-wrapper-top .nav-tabs {
    margin-top: 0.5em;
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    border: none;
}

.kslplus-haushaltstab-wrapper-top .nav-item {
    text-align: center;
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    font-size: 1.5em;
    font-weight: 900;
    cursor: pointer;
    margin-bottom: 0.2em;
}

    .kslplus-haushaltstab-wrapper-top .nav-item:not(:last-child) {
        margin-right: 5px;
    }

    /* 2 Reiter */
    .kslplus-haushaltstab-wrapper-top .nav-item:nth-child(1):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-top .nav-item:nth-child(2):nth-last-child(1) {
        min-width: calc((95%-5px)/2);
    }



    .kslplus-haushaltstab-wrapper-top .nav-item .active {
        color: #424242;
        color: var(--colSchriftNavAktiv);
        background-color: #A9F5F2;
        background-color: var(--colTabObenAktiv);
        border: solid !important;
        border-color: #828282 !important;
        border-color: var(--colTabActiveBolder) !important;
    }

.kslplus-haushaltstab-wrapper-top .nav-link {
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #D8D8D8;
    background-color: var(--colTabObenInaktiv);
    -moz-border-radius-topright: 50%;
    border-radius: 5px 15px 5px 5px;
    /*
    border: solid !important;
    border-color: var(--colSchriftNavHover) !important;
    */
    border: none;
}

    .kslplus-haushaltstab-wrapper-top .nav-link:hover {
        color: #088A85;
        color: var(--colSchriftNavHover);
    }





.kslplus-haushaltstab-wrapper-middle .row {
    overflow: auto;
}


.kslplus-haushaltstab-wrapper-middle .nav-tabs {
    margin-top: 0;
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
}

.kslplus-haushaltstab-wrapper-middle .nav-tabs {
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    cursor: pointer;
}

.kslplus-haushaltstab-wrapper-middle .nav-item {
    text-align: center;
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    font-size: 1.4em;
    font-weight: 700;
    cursor: pointer;
}

    /* 2 Reiter */
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(1):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(2):nth-last-child(1) {
        min-width: calc((90%-5px)/2);
    }

    /* 3 Reiter */
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(1):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(2):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(3):nth-last-child(1) {
        min-width: calc((90%-10px)/3);
    }

    /* 4 Reiter */
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(1):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(2):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(3):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(4):nth-last-child(1) {
        min-width: calc((90%-15px)/4);
    }

    /* 5 Reiter */
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(1):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(2):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(3):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(4):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-middle .nav-item:nth-child(5):nth-last-child(1) {
        min-width: calc((90%-15px)/5);
    }



    .kslplus-haushaltstab-wrapper-middle .nav-item .active {
        color: #424242;
        color: var(--colSchriftNavAktiv);
        background-color: #CEF6F5;
        background-color: var(--colTabMitteAktiv);
        cursor: pointer;
    }

.kslplus-haushaltstab-wrapper-middle .nav-link {
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #E6E6E6;
    background-color: var(--colTabMitteInaktiv);
    cursor: pointer;
}

    .kslplus-haushaltstab-wrapper-middle .nav-link:hover {
        color: #088A85;
        color: var(--colSchriftNavHover);
    }


.kslplus-haushaltstab-wrapper-bottom .row {
    overflow: auto;
}


.kslplus-haushaltstab-wrapper-bottom .nav-tabs {
    margin-top: 0;
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
}


.kslplus-haushaltstab-wrapper-bottom .nav-tabs {
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    cursor: pointer;
}

.kslplus-haushaltstab-wrapper-bottom .nav-item {
    text-align: center;
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
}

    /* 2 Reiter */
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(1):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(2):nth-last-child(1) {
        min-width: calc((85%-5px)/2);
    }

    /* 3 Reiter */
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(1):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(2):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(3):nth-last-child(1) {
        min-width: calc((85%-10px)/3);
    }

    /* 4 Reiter */
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(1):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(2):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(3):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(1):nth-last-child(1) {
        width: calc((85%-15px)/4);
    }

    /* 5 Reiter */
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(1):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(2):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(3):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(4):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(5):nth-last-child(1) {
        min-width: calc((85%-20px)/5);
    }

    /* 6 Reiter */
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(1):nth-last-child(6),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(2):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(3):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(4):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(5):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(6):nth-last-child(1) {
        min-width: calc((85%-25px)/6);
    }

    /* 7 Reiter */
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(1):nth-last-child(7),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(2):nth-last-child(6),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(3):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(4):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(5):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(6):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-bottom .nav-item:nth-child(7):nth-last-child(1) {
        min-width: calc((85%-30px)/7);
    }


    .kslplus-haushaltstab-wrapper-bottom .nav-item .active {
        color: #424242;
        color: var(--colSchriftNavAktiv);
        background-color: #E0F8F7;
        background-color: var(--colTabUntenAktiv);
        cursor: pointer;
    }

.kslplus-haushaltstab-wrapper-bottom .nav-link {
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EDEDED;
    background-color: var(--colTabUntenInaktiv);
    cursor: pointer;
}

    .kslplus-haushaltstab-wrapper-bottom .nav-link:hover {
        color: #088A85;
        color: var(--colSchriftNavHover);
    }



.kslplus-haushaltstab-wrapper-bottom .nav-tabs {
    margin-top: 0;
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
}


.kslplus-haushaltstab-wrapper-bottom .nav-tabs {
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    cursor: pointer;
}

.kslplus-haushaltstab-wrapper-very-bottom .nav-item {
    text-align: center;
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    font-size: 1.2em;
    font-weight: 400;
    cursor: pointer;
}

    /* 2 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(1) {
        min-width: calc((80%-5px)/2);
    }

    /* 3 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(1) {
        min-width: calc((80%-10px)/3);
    }

    /* 4 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(4):nth-last-child(1) {
        width: calc((80%-15px)/4);
    }

    /* 5 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(4):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(5):nth-last-child(1) {
        min-width: calc((80%-20px)/5);
    }

    /* 6 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(6),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(4):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(5):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(6):nth-last-child(1) {
        min-width: calc((80%-25px)/6);
    }

    /* 7 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(7),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(6),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(4):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(5):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(6):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(7):nth-last-child(1) {
        min-width: calc((80%-30px)/7);
    }

    /* 8 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(8),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(7),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(6),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(4):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(5):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(6):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(7):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(8):nth-last-child(1) {
        min-width: calc((80%-35px)/8);
    }

    /* 9 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(9),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(8),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(7),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(4):nth-last-child(6),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(5):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(6):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(7):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(8):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(9):nth-last-child(1) {
        min-width: calc((80%-40px)/9);
    }

    /* 10 Reiter */
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(1):nth-last-child(10),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(2):nth-last-child(9),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(3):nth-last-child(8),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(4):nth-last-child(7),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(5):nth-last-child(6),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(6):nth-last-child(5),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(7):nth-last-child(4),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(8):nth-last-child(3),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(9):nth-last-child(2),
    .kslplus-haushaltstab-wrapper-very-bottom .nav-item:nth-child(10):nth-last-child(1) {
        min-width: calc((80%-450px)/10);
    }


    .kslplus-haushaltstab-wrapper-very-bottom .nav-item .active {
        color: #424242;
        color: var(--colSchriftNavAktiv);
        background-color: #E4FBFA;
        background-color: var(--colTabGanzUntenAktiv);
        cursor: pointer;
    }

.kslplus-haushaltstab-wrapper-very-bottom .nav-link {
    color: #424242;
    color: var(--colSchriftNavInaktiv);
    background-color: #F2F2F2;
    background-color: var(--colTabGanzUntenInaktiv);
    cursor: pointer;
}

    .kslplus-haushaltstab-wrapper-very-bottom .nav-link:hover {
        color: #088A85;
        color: var(--colSchriftNavHover);
    }
/*
:where(.kslplus-haushaltstab-wrapper) .nav-link {
    margin-top: 0.5em;
    color: lightgray;
    font-size: 1.3em;
    cursor: pointer;
}

:where(.kslplus-haushaltstab-wrapper-top) .nav-link {
    margin-top: 0.5em;
    color: var(--colSchriftNavInaktiv);
    font-size: 1.3em;
    cursor: pointer;
}



    :where(.kslplus-haushaltstab-wrapper-top) .nav-link:hover {
        color: var(--colSchriftNavHover);
    }
    */
.kslplus-app-wrapper {
    align-items: center;
    justify-content: center
}

.kslplus-headline-wrapper {
    margin-top: 0.5em;
    min-width: 300px;
    height: 200px;
    background-color: lightblue;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    justify-content: flex-start;
    /*padding:0;*/
}

.kslplus-headline {
    padding: 0 15px 0 15px;
}

    .kslplus-headline .col {
        /*padding: 0px !important;*/
    }

.kslplus-headline-wrapper-left {
    margin-top: 0.5em;
    min-width: 300px;
    height: 200px;
    background-color: #A9F5F2;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    justify-content: flex-start;
    background-image: linear-gradient(to right, #A9F5F2, #FFFFFF );
}

.kslplus-headline-wrapper-middle {
    margin-top: 0.5em;
    min-width: 300px;
    height: 200px;
    background-color: #FFFFFF;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    justify-content: flex-start;
    /*padding: 0 !important;*/
}

.kslplus-headline-wrapper-right {
    margin-top: 0.5em;
    min-width: 300px;
    height: 200px;
    background-color: #A9F5F2;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    justify-content: flex-start;
    background-image: linear-gradient(to left, #A9F5F2, #FFFFFF );
    padding: 0 !important;
}

#kslplus-wappen {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.kslplus-headline-main {
    font-size: 3em;
    text-align: left;
    vertical-align: middle;
    color: #424242;
    color: var(--colSchriftHeadlineMain);
}

.kslplus-headline-second {
    font-size: 1.5em;
    text-align: left;
    vertical-align: middle;
    color: #727272;
    color: var(--colSchriftHeadlineScond);
}

.kslplus-headline-third {
    font-size: 1.8em;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
}

.kslplus-dokument-container {
    overflow: auto;
    overflow-x: hidden;
    height: 150px;
}

.kslplus-dokument-expand {
    font-size: 1.5em;
}

.kslplus-dokument-tree {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
}

    .kslplus-dokument-tree .MuiTreeItem-label:hover {
        background-color: #A9F5F2;
        background-color: var(--colHintergundSelected);
    }

    .kslplus-dokument-tree .MuiTreeItem-label {
        font-family: 'Arial', Arial !important;
    }

.kslplus-dokument-link {
    font-size: 1.5em;
    margin-left: 1em;
}

.kslplus-img-wappen {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.kslplus-uhconfig-wrapper {
    margin-top: 0.5em;
    min-width: 300px;
    background-color: #EFFBFB;
    background-color: var(--colHintergrund);
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    justify-content: flex-start;
    padding: 0 15px 0 15px;
    font-weight: bold;
    font-size: 1.3rem;
    /*padding:0;*/
}

.ksl-grafik-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

.ksl-grafik-container-piechart {
    
    
    width: 100%;
}

.ksl-container-piechart5 {
    max-width: 500px;
}

.ksl-container-piechart3 {
    
    max-width: 1200px;
}

.ksl-container-piechart2 {
    max-width: 1600px;
    
}

.ksl-container-barchart3 {
    max-width: 1200px;
}

.ksl-container-barchart2 {
    max-width: 1600px;
    width: 50%;
    
    justify-content: center;
    display: flex;
}

.ksl-grafik-container-barchart {
    width: 66%;

    
}

.kslplus-maintext-wrapper {
    min-width: 300px;
    margin-top: 0;
    justify-content: flex-start;
    font-size: 1.2em;
}

.kslplus-headline-haushalt {
    font-size: 1.8em;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    margin-bottom: 0.5em;
}

.kslplus-dataset-wrapper {
}

.kslplus-dataset-header {
    font-size: 1.2em;
    font-weight: bold;
}

.kslplus-dataset-value {
    font-size: 1.2em;
}

.kslplus-tabellen-bezeichnung {
    overflow: hidden;    
    text-overflow: ellipsis;
    float: left;
}

.kslplus-expand-column {
    width: 35px;    
}

.kslplus-tabellen-header {    
    float: right;
    overflow: hidden;
    text-overflow: ellipsis;    
}

.kslplus-tabellen-header-sub1 {
    text-align: right;    
    overflow: hidden;
    text-overflow: ellipsis;
}

.kslplus-tabellen-header-sub2 {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kslplus-tabellen-data {    
    overflow: hidden;
    text-overflow: ellipsis;    
}

.kslplus-tabellen-footer {
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.kslplus-tabellen-footer-invest {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;    
}

.kslplus-tabellen-footer-invest1 {
    margin-top: 0.5rem;
    
    overflow: hidden;
    text-overflow: ellipsis;
}

.kslplus-tabellen-footer-invest-border {
    margin-top: 0.5rem;    
    border-top: solid;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kslplus-tabellen-expanded .row-expansion-style {
    padding: 0px;
}

.kslplus-hhcomp-content .kslplus-tabellen-expanded tfoot {
    display: none;
}

.kslplus-tabellen-expanded table {
    margin-bottom: 0;
}

.kslplus-tooltip {
    max-width: 100%;
    white-space: pre-line;
}



.kslplus-menu-popover .arrow:after {
    border-bottom-color: #f7f7f7 !important;
}


.kslplus-menu-popover .popover {
    
    max-width: 400px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-shadow: 0 4.7px 1.3px rgba(80, 93, 104, 0.057), 0 8.9px 4.2px rgba(80, 93, 104, 0.083), 0 14px 19px rgba(80, 93, 104, 0.14);    
}

.kslplus-menu-popover-body {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    background: #f7f7f7;
    
}

.kslplus-collaps-popover {
    background-color: white;
    color: red;
}

.kslplus-change-tooltip {
    width: 1200px;
}

.kslplus-info-badge-container {    
    display: inline;
}

.kslplus-info-progkom-badge-container {
    margin-right: 5px;
    display: inline;
}

.kslplus-info-badge {
    margin-left: 0.5em;
    background-color: yellow;
    color: black;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    vertical-align: central;
}

.kslplus-info-drilldown {
    margin-right: 0.5em;
    display: inline-block;
    color: black;
    
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    vertical-align: central;
}

.kslplus-hidden {
    display: none;
}

.kslplus-level-drawer {
    min-height: 200px;
    height: 100%;
    position: relative;
    flex-shrink: 0;
}

.kslplus-level-drawer-paper {
    min-height: 200px;
    width: 100%;
    height: 100%;
    position: absolute !important;
    margin-left: 0px;
    height: 100%;
    display: none;
}

.kslplus-haushalts-treeselect .tag {
    color: red;
}

.kslplus-haushalts-treeselect .tag-remove {
    color: red;
}

:where(.kslplus-haushalts-treeselect) .dropdown {
    z-index: 5000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.kslplus-hhcomp-content {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    max-height: 1000px;
    overflow-y: auto;
    overflow-x: hidden;
}

.kslplus-content {
    flex-grow: 1;
    margin-left: 0;
}

.kslplus-gliederung-accordion1 {
}

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    font-size: 1.3em;
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
}

    .accordion__button:hover {
        background-color: #ddd;
    }

    .accordion__button:before {
        display: inline-block;
        content: '';
        height: 15px;
        width: 15px;
        margin-right: 12px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }

    .accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
    }

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    -webkit-animation: fadein 0.35s ease-in;
            animation: fadein 0.35s ease-in;
}
/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */
@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.kslplus-beschreibung-collapse-gradient {
    white-space: pre-line;
    width: 97%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
    border-color: transparent;
}

    .kslplus-beschreibung-collapse-gradient.active {
        opacity: 0;
    }

.kslplus-beschreibung-content {
    width: 100%;
    border: 1px solid transparent;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    transition: 300ms;
    color: #000000;
    color: var(--colSchriftAccordionContent);
    background-color: #EFFBFB;
    background-color: var(--colSchriftAccordionContentHintergrund);
}

.kslplus-beschreibung-text {
    width: 100%;
    text-align: left;
    color: #FFFFFF;
    color: var(--colSchriftAccordion);
    font-weight: bold;
}

.kslplus-beschreibung-toggle {
    width: 100%;
    /*margin: 1rem 0;*/
    margin-top: 1px;
    margin-bottom: 3px;
    border: 1px solid transparent;
    /*text-transform: uppercase;*/
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 2px 4px #888;
    transition: background-color 100ms;
    background-color: #424242;
    background-color: var(--colHintergrundAccordion);
    color: #FFFFFF;
    color: var(--colSchriftAccordion);
}

    .kslplus-beschreibung-toggle.active {
        background-color: #727272;
        background-color: var(--colHintergrundAccordionAktiv);
    }

.kslplus-beschreibung-icon {
    width: 1em;
    height: 1em;
    margin-right: 5px;
    transition: -webkit-transform 100ms cubic-bezier(0, 1, 0, 1);
    transition: transform 100ms cubic-bezier(0, 1, 0, 1);
    transition: transform 100ms cubic-bezier(0, 1, 0, 1), -webkit-transform 100ms cubic-bezier(0, 1, 0, 1);
    will-change: transform;
    stroke-width: 3px;
    stroke: #FFFFFF;
    stroke: var(--colSchriftAccordion);
}

    .kslplus-beschreibung-icon.expanded {
        -webkit-transform: rotateZ(-179.99deg);
                transform: rotateZ(-179.99deg);
    }

.rotate90 {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
}


.kslplus-grafik-collapse-gradient {
    white-space: pre-line;
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
    border-color: transparent;
    
}

    .kslplus-grafik-collapse-gradient   .active {
        opacity: 0;
    }

.kslplus-grafik-collaps-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    
}
.kslplus-grafik-collaps-wrapper .kslplus-beschreibung-text {
    width: 100%;
    text-align: left;
    color: #FFFFFF;
    color: var(--colSchriftAccordion);
    font-weight: bold;
    margin-left: 5rem;
}

.kslplus-grafik-collaps-wrapper .kslplus-info-badge {
        
    margin-right: 5px;
}

.kslplus-footer-invest {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

/* Popover Modal */
.kslplus-invest-popover .popover {
    max-width: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-shadow: 0 4.7px 1.3px rgba(80, 93, 104, 0.057), 0 8.9px 4.2px rgba(80, 93, 104, 0.083), 0 14px 19px rgba(80, 93, 104, 0.14);
}
/* Popover layout */
.kslplus-invest-popover-body {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    background: #fff;
}

.kslplus-invest-popover-block {
    min-width: 900px;
    grid-gap: 1em;
    gap: 1em;
    max-width: 900px;
    border-bottom: 1px solid var(--colorText);
    padding-bottom: 0.5rem;
    align-items: center;
}

.kslplus-invest-popover-block-small {
    min-width: 450px;
    max-width: 450px;
    border-bottom: 1px solid var(--colorText);
    padding-bottom: 0.5rem;
    align-items: center;
}

.kslplus-invest-popover-beschreibung {
    white-space: pre-line;
}

.kslplus-invest-popover-container-beschreibungen {
    margin-bottom: 1em;
}

.kslplus-invest-popover-bezeichnung {
    font-weight: 500;
}

.kslplus-invest-popover-wert {
    text-align: right;
}

.kslplus-content .expand-cell-header {
    width: 35px !important
}

.kslplus-content .expand-cell {
    width: 35px
}


/* Popover Modal */
.kslplus-header-popover .popover {    
    max-width: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-shadow: 0 4.7px 1.3px rgba(80, 93, 104, 0.057), 0 8.9px 4.2px rgba(80, 93, 104, 0.083), 0 14px 19px rgba(80, 93, 104, 0.14);
}
/* Popover layout */
.kslplus-header-popover-body {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    background: #fff;
}

.kslplus-header-tt-block {
    display: block;
    margin-left: 20px;
}
    .kslplus-header-tt-block .kslplus-sign {
        display: inline-block;
        width: 30px;
    }

    .kslplus-header-tt-block .kslplus-label {
        display: inline-block;
    }

/* Popover Modal */
.kslplus-change-popover .popover {
    max-width: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-shadow: 0 4.7px 1.3px rgba(80, 93, 104, 0.057), 0 8.9px 4.2px rgba(80, 93, 104, 0.083), 0 14px 19px rgba(80, 93, 104, 0.14);
}
/* Popover layout */
.kslplus-change-popover-body {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    background: #fff;
    
}

.kslplus-change-popover-doppisch-detailiert-body {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    background: #fff;
    min-width: 800px;    
    max-width: 800px;
}

.kslplus-change-popover-block {
    min-width: 600px;
    grid-gap: 1em;
    gap: 1em;
    max-width: 600px;
    border-bottom: 1px solid var(--colorText);
    padding-bottom: 0.5rem;
    align-items: center;
}

.kslplus-change-popover-headline {
    font-weight: 500;
}

.kslplus-change-popover-bezeichnung {
}

.kslplus-change-popover-wert {
    text-align: right;
}

.kslplus-invest-delete-filteres {
    border: none;
    background-color: #FFFFFF;
    background-color: var(--colTabellenHintergrund);
    position: relative;
    top: 86px;
}

// styles f�r Quill genertierten Inhalt .ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-font-serif {
    font-family: sans-serif;
}

.ql-font-monospace {
    font-family: monospace;
}

.kslplus-abcanlysis {
    margin: 0 auto;
    min-width: 600px;
    //max-width: 1200px;
}

    .kslplus-abcanlysis tfoot {
        border-top: 4px solid black;
        border-bottom: 4px solid black;
    }

    .kslplus-abcanlysis thead {
        border-top: 4px solid black;
        border-bottom: 4px solid black;
    }


    .kslplus-abcanlysis .kslplus-content tfoot {
        border-top: unset;
        border-bottom: unset;
    }

    .kslplus-abcanlysis .kslplus-content thead {
        border-top: unset;
        border-bottom: unset;
    }

.kslplus-abcanlysis-row {
    font-weight: bold;
}

.kslplus-abcanlysis-rowborder {
    font-weight: bold;
    border-top: 4px solid black;
}

.kslplus-header-sort {
    color: lightgray;
}

.kslplus-header-sort-up {
    color: black;
    font-weight: 900;
}

.kslplus-header-sort-down {
    color: black;
    font-weight: 900;
}

.kslplus-kennzahl-ergebnis-zeile {
    font-weight: bold;
}

.kslplus-kennzahl-zeile {
}

.kslplus-topeasanalyse-tt-child  {
    display: block;
        
}
    .kslplus-topeasanalyse-tt-child .kslplus-sign {
        display: inline-block;
        width: 15px;
    }

    .kslplus-topeasanalyse-tt-child .kslplus-label {
        display: inline-block;
        width: 120px;
    }

    .kslplus-topeasanalyse-tt-child .kslplus-wert {
        display: inline-block;
        text-align: right;
        width: 150px;
    }

svg > g > g.google-visualization-tooltip {
    pointer-events: none
}


.ksl-plus-table-ubw {
    overflow-y: auto;
    max-height: 550px;
}

.ksl-plus-table-ubw th {
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        background-color: #fff;
        z-index: 1;
    }


.ksl-plus-table {
    overflow-y: auto;
    max-height: 550px;
}

    .ksl-plus-table th {
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        background-color: #fff;
        z-index: 1;
    }
  
.tag {
    background-color: #f4f4f4;
    border: 1px solid #e9e9e9;
    padding: 2px 0 2px 2px;
    border-radius: 2px;
    display: inline-block
}

    .tag:focus-within {
        background-color: #e9e9e9;
        border-color: #a0a0a0
    }

.tag-remove {
    color: #a0a0a0;
    font-size: 75%;
    line-height: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none
}

    .tag-remove.disabled, .tag-remove.readOnly {
        cursor: not-allowed
    }

    .tag-remove:focus {
        color: #3c3c3c
    }

.node > label {
    cursor: pointer;
    margin-left: 2px
}

.tag-list {
    display: inline;
    padding: 0;
    margin: 0
}

.tag-item {
    display: inline-block;
    margin: 4px
}

    .tag-item .search {
        border: none;
        border-bottom: 1px solid #ccc;
        outline: none;
        width:280px;
    }

    .tag-item:last-child {
        margin-right: 4px
    }

.node {
    list-style: none;
    white-space: nowrap;
    padding: 4px
}

    .node.leaf.collapsed {
        display: none
    }

    .node.disabled > * {
        color: gray;
        cursor: not-allowed
    }

    .node.match-in-children.hide .node-label {
        opacity: .5
    }

    .node.focused {
        background-color: #f4f4f4
    }

.toggle {   
    height: 100px;
    white-space: pre;
    margin-right: 4px;
    cursor: pointer;
    font-size: x-large;
    line-height: 0.2em;
    
}

    .toggle:after {
        content: " "
    }

    .toggle.collapsed:after {        
        content: "+" ;        
    }

    .toggle.expanded:after {
        content: "-"
    }

.searchModeOn .toggle {
    display: none
}

.checkbox-item, .radio-item {
    vertical-align: middle;
    margin: 0 4px 0 0
}

    .checkbox-item.simple-select, .radio-item.simple-select {
        display: none
    }

.hide:not(.match-in-children) {
    display: none
}

.react-dropdown-tree-select .dropdown {
    position: relative;
    display: table
}

    .react-dropdown-tree-select .dropdown .dropdown-trigger {
        padding: 4px;
        line-height: 20px;
        max-height: 200px;
        display: inline-block;
        overflow: auto;
        border: 3px solid red
    }

        .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
            cursor: pointer
        }

            .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
                content: "\25BC";
                vertical-align: middle;
                color: #3c3c3c;
                margin-right: 2px
            }

            .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
                content: "\25B2";
                vertical-align: middle;
                color: #3c3c3c;
                margin-right: 2px
            }

            .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
                cursor: not-allowed
            }

                .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after {
                    color: #b9b9b9
                }

    .react-dropdown-tree-select .dropdown .dropdown-content {
        position: absolute;
        padding: 4px;
        z-index: 1;
        background: #fff;
        border-top: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 5px 8px rgba(0,0,0,.15)
    }

        .react-dropdown-tree-select .dropdown .dropdown-content .search {
            width: 100%;
            border: none;
            border-bottom: 1px solid #ccc;
            outline: none
        }

        .react-dropdown-tree-select .dropdown .dropdown-content ul {
            margin: 0;
            padding: 0
        }

/*# sourceMappingURL=styles.css.map*/

